import { Responsive, Segment } from 'semantic-ui-react';

const styles = {
  containerDesktop: {
    display: 'flex',
    height: '64px',
    alignItems: 'center',
    backgroundColor: 'rgb(255, 255, 255, .3)',
    padding: '0px 16px 0px 16px',
  },
  containerMobile: {
    display: 'flex',
    height: '64px',
    alignItems: 'center',
    backgroundColor: 'rgb(255, 255, 255, .3)',
    padding: '0px 12px 0px 12px',
  },
  title: {
    color: 'white',
    fontSize: '14px',
    fontWeight: '500',
    paddingLeft: '8px',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.12px',
  },
};
Object.freeze(styles);

function MapIcon(props) {
  const { title } = props;

  return (
    <div>
      <Responsive as={Segment} minWidth={601} style={styles.containerDesktop}>
        <svg
          fill="white"
          height="24px"
          width="24px"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
        >
          <path d="M16 24l-6.09-8.6A8.14 8.14 0 0 1 16 2a8.08 8.08 0 0 1 8 8.13 8.2 8.2 0 0 1-1.8 5.13zm0-20a6.07 6.07 0 0 0-6 6.13 6.19 6.19 0 0 0 1.49 4L16 20.52 20.63 14A6.24 6.24 0 0 0 22 10.13 6.07 6.07 0 0 0 16 4z" />
          <circle cx="16" cy="9" r="2" />
          <path d="M28 12h-2v2h2v14H4V14h2v-2H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h24a2 2 0 0 0 2-2V14a2 2 0 0 0-2-2z" />
        </svg>
        <p style={styles.title}>{title}</p>
      </Responsive>
      <Responsive as={Segment} maxWidth={600} style={styles.containerMobile}>
        <svg
          fill="white"
          height="24px"
          width="24px"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
        >
          <path d="M16 24l-6.09-8.6A8.14 8.14 0 0 1 16 2a8.08 8.08 0 0 1 8 8.13 8.2 8.2 0 0 1-1.8 5.13zm0-20a6.07 6.07 0 0 0-6 6.13 6.19 6.19 0 0 0 1.49 4L16 20.52 20.63 14A6.24 6.24 0 0 0 22 10.13 6.07 6.07 0 0 0 16 4z" />
          <circle cx="16" cy="9" r="2" />
          <path d="M28 12h-2v2h2v14H4V14h2v-2H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h24a2 2 0 0 0 2-2V14a2 2 0 0 0-2-2z" />
        </svg>
      </Responsive>
    </div>
  );
}

export default MapIcon;
