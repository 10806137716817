import { Suspense } from 'react';
import { Provider } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Map from './component/Map';
import createStore from './store';
import fetchHost from './store/customization/host/action/fetchHost';
import Navbar from './component/Navbar';

const store = createStore();

const URL = new URLSearchParams(window.location.search);
const checkIFrame = !!(URL.has('version') && URL.get('version') === 'lite');
const brandColorPreview = URL.has('_customizerPreview[theme][brandColor]') ? URL.get('_customizerPreview[theme][brandColor]') : false;
const brandLogoPreview = URL.has('_customizerPreview[theme][logo][url]') ? URL.get('_customizerPreview[theme][logo][url]') : false;
const latitudePreview = URL.has('_customizerPreview[theme][coordinates][latitude]') ? URL.get('_customizerPreview[theme][coordinates][latitude]') : false;
const longitudePreview = URL.has('_customizerPreview[theme][coordinates][longitude]') ? URL.get('_customizerPreview[theme][coordinates][longitude]') : false;

function App() {
  return (
    <Suspense fallback="loading">
      <Provider store={store}>
        <Map
          checkIFrame={checkIFrame}
          brandColorPreview={brandColorPreview}
        />
        <Navbar
          checkIFrame={checkIFrame}
          brandColorPreview={brandColorPreview}
          brandLogoPreview={brandLogoPreview}
        />
      </Provider>
    </Suspense>
  );
}

const hostUrl = window.location.host;

store.dispatch(fetchHost(hostUrl, latitudePreview, longitudePreview));

export default withTranslation()(App);
