import _ from 'lodash';
import {
  HOST_REQUEST,
  HOST_SUCCESS,
  HOST_FAILURE,
} from './types';
import convertWebappStringToLatLngObject from '../../../utils/geometry';

const {
  REACT_APP_VIRTA_DEFAULT_LOGO,
  REACT_APP_VIRTA_DEFAULT_CENTERPOINT,
  REACT_APP_GOOGLEPLAY_LINK,
  REACT_APP_APPSTORE_LINK,
} = process.env;
const defaultCenterPoint = convertWebappStringToLatLngObject(REACT_APP_VIRTA_DEFAULT_CENTERPOINT);

const defaultState = {
  is_fetching: false,
  brandColor: '#cccccc',
  brandName: 'virta',
  logo: null,
  googlePlayStoreLink: REACT_APP_GOOGLEPLAY_LINK,
  appleAppStoreLink: REACT_APP_APPSTORE_LINK,
  brandCode: 'virta',
  parkedUrl: 'virta.webapp.virtaglobal.com',
  defaultLocale: 'en',
  webAppCenter: defaultCenterPoint,
  error: null,
};

// eslint-disable-next-line default-param-last
export default (state = defaultState, action) => {
  switch (action.type) {
    case HOST_REQUEST:
      return { ...defaultState, is_fetching: true };
    case HOST_SUCCESS:
      // eslint-disable-next-line no-case-declarations,max-len
      const webAppCenterPreviewCheck = (action.latitudePreview !== false && action.longitudePreview !== false)
        // eslint-disable-next-line max-len
        ? { lat: Number.parseFloat(action.latitudePreview), lng: Number.parseFloat(action.longitudePreview) }
        : defaultCenterPoint;
      // eslint-disable-next-line no-case-declarations
      const hostCustomization = action.host.service;
      // eslint-disable-next-line no-case-declarations
      const usingWebAppV3 = hostCustomization.activeCustomization.settings.usingWebAppV3;
      // eslint-disable-next-line no-case-declarations
      let webAppCenter = webAppCenterPreviewCheck;
      if (
        usingWebAppV3
        && usingWebAppV3 !== null
        && usingWebAppV3 === true
        && action.latitudePreview === false
        && action.longitudePreview === false) {
        const coordinates = hostCustomization.activeCustomization.theme.coordinates;
        if (coordinates && coordinates.latitude && coordinates.longitude) {
          webAppCenter = {
            lat: Number.parseFloat(coordinates.latitude),
            lng: Number.parseFloat(coordinates.longitude),
          };
        }
      }

      // eslint-disable-next-line no-case-declarations
      const googlePlayStoreLink = hostCustomization.brand.settings.googlePlayStoreLink;
      // eslint-disable-next-line no-case-declarations
      const appleAppStoreLink = hostCustomization.brand.settings.appleAppStoreLink;

      // eslint-disable-next-line no-case-declarations
      const mobileApplicationLink = {
        googlePlayStoreLink: (googlePlayStoreLink && googlePlayStoreLink !== '') ? googlePlayStoreLink : REACT_APP_GOOGLEPLAY_LINK,
        appleAppStoreLink: (appleAppStoreLink && appleAppStoreLink !== '') ? appleAppStoreLink : REACT_APP_APPSTORE_LINK,
      };

      return {
        ...defaultState,
        brandColor: hostCustomization.activeCustomization.theme.brandColor,
        logo: hostCustomization.activeCustomization.theme.logo.url,
        brandName: hostCustomization.brand.name,
        brandCode: hostCustomization.brand_code,
        parkedUrl: hostCustomization.parkedUrl,
        defaultLocale: hostCustomization.brand.default_locale,
        webAppCenter,
        googlePlayStoreLink: mobileApplicationLink.googlePlayStoreLink,
        appleAppStoreLink: mobileApplicationLink.appleAppStoreLink,
        impressumLink: _.get(hostCustomization.brand.settings, 'impressumLink', null),
      };
    case HOST_FAILURE:
      // eslint-disable-next-line no-case-declarations,max-len
      const webAppCenterCheck = (action.latitudePreview !== false && action.longitudePreview !== false)
        // eslint-disable-next-line max-len
        ? { lat: Number.parseFloat(action.latitudePreview), lng: Number.parseFloat(action.longitudePreview) }
        : defaultCenterPoint;
      return {
        ...defaultState,
        logo: REACT_APP_VIRTA_DEFAULT_LOGO,
        webAppCenter: webAppCenterCheck,
        error: action.error,
      };
    default:
      return state;
  }
};
