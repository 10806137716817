import {
  createInformationRequestAction,
  createInformationSuccessAction,
  createInformationFailureAction,
} from './creators';

const fetchInformation = (station) => async (dispatch) => {
  dispatch(createInformationRequestAction());

  try {
    const { REACT_APP_VIRTA_API_V4 } = process.env;
    const result = await fetch(`${REACT_APP_VIRTA_API_V4}stations/${station}`);

    if (result.ok) {
      const information = await result.json();
      dispatch(createInformationSuccessAction(information));
    } else {
      throw new Error('Network response was not ok with fetching station information');
    }
  } catch (error) {
    console.log('There has been a problem with your fetch operation: ', error.message);
    dispatch(createInformationFailureAction(error));
  }
};

export default fetchInformation;
