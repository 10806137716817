import {
  createHostRequestAction,
  createHostSuccessAction,
  createHostFailureAction,
} from './creators';
import fetchCustomerLinks from '../../customer-links/action/fetchCustomerLinks';

// eslint-disable-next-line default-param-last
const fetchHost = (hostUrl = '', latitudePreview, longitudePreview) => async (dispatch) => {
  dispatch(createHostRequestAction());

  try {
    const { REACT_APP_VIRTAGLOBAL_API } = process.env;
    await fetch(`${REACT_APP_VIRTAGLOBAL_API}hosts/${hostUrl}`)
      .then((response) => {
        if (!response.ok || response.status !== 200) {
          throw new Error('Network response was not ok with fetching host information');
        }
        return response.json();
      }).then((host) => {
        dispatch(createHostSuccessAction(host, latitudePreview, longitudePreview));
        dispatch(fetchCustomerLinks());
      });
  } catch (error) {
    console.log('There has been a problem with your fetch operation: ', error.message);
    dispatch(createHostFailureAction(error, latitudePreview, longitudePreview));
  }
};

export default fetchHost;
