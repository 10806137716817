import {
  createStationRequestAction,
  createStationSuccessAction,
  createStationFailureAction,
} from './creators';
import fetchStatus from '../../status/action/fetchStationStatus';

const fetchStation = (blocks) => async (dispatch) => {
  dispatch(createStationRequestAction());

  const { REACT_APP_VIRTA_MAP_URL } = process.env;

  try {
    const result = await fetch(
      REACT_APP_VIRTA_MAP_URL,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(blocks),
      },
    );

    if (result.ok) {
      const station = await result.json();

      const stations = [];
      const clusters = [];
      const stationIds = [];

      Object.values(station.MapBlocks).forEach((data) => {
        if (data.Stations !== '') {
          Object.values(data.Stations).forEach((stationData) => {
            stations.push(stationData);
            stationIds.push(stationData.id);
          });
        }

        if (data.Clusters !== '') {
          Object.values(data.Clusters).forEach((cluster) => {
            clusters.push(cluster);
          });
        }
      });

      dispatch(fetchStatus(stationIds, stations, clusters));
      dispatch(createStationSuccessAction(station));
    } else {
      throw new Error('Network response was not ok with fetching map blocks');
    }
  } catch (error) {
    console.log('There has been a problem with your fetch operation: ', error.message);
    dispatch(createStationFailureAction(error));
  }
};

export default fetchStation;
